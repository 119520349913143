<template>
  <div class="d-flex justify-content-between new-block">
    <div>
      <h2 class="float-start">
        {{
          $t(
            "CalendarViews.NewCalendar",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}
      </h2>
    </div>
    <div>
      <ActionButtons
        @methodSave="onSubmit('0', 'btn-save')"
        @methodSaveAndNew="onSubmit('1', 'btn-save-and-new')"
        @clear="onClear()"
        :isNewRecord="true"
      />
    </div>
  </div>
  <form class="form-calendar-new pe-2 ps-2" novalidate>
    <div v-if="errors.length > 0" class="alert alert-warning">
      <ul class="mb-0">
        <li v-for="error in errors" v-bind:key="error">
          {{ error }}
        </li>
      </ul>
    </div>
    <div v-if="isSuccess" class="alert alert-success">
      {{
        $t(
          "Messages.SuccessMessage",
          {},
          { locale: this.$store.state.activeLang }
        )
      }}
    </div>
    <div class="row row-cols-1">
      <div class="col col-md-4">
        <label for="Name" class="form-label required"
          >{{
            $t(
              "CalendarViews.Name",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}
        </label>
        <input
          type="text"
          class="form-control"
          id="Name"
          v-model="calendarViewData.name"
          :autocomplete="this.$isAutoComplete"
          :spellcheck="this.$isTextSpellCheck"
          required
        />
      </div>
      <div class="col col-md-4">
        <label for="startDateField" class="form-label required">{{
          $t(
            "CalendarViews.StartDate",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label>
        <FormSelect
          type="SelectWithRemoteDataAndInternalSearch"
          @onChange="onChangeStartDate"
          :isDetailIconShow="true"
          :routeLink="
            String.format(
              '#/CustomObject/EditField/{0}&fieldId=',
              this.$route.params.customObjectId
            )
          "
          :allowEmpty="false"
          :isParameters="true"
          :isGatewayRequest="true"
          :requestUrl="
            String.format(
              '/Lcdp-SummaryCalendarViewableFields?parentId={0}&coId={0}',
              this.$route.params.customObjectId
            )
          "
        />
      </div>
      <div class="col col-md-4">
        <label for="endDateField" class="form-label">{{
          $t(
            "CalendarViews.EndDate",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label>
        <FormSelect
          type="SelectWithLocalData"
          :isDetailIconShow="true"
          :routeLink="
            String.format(
              '#/CustomObject/EditField/{0}&fieldId=',
              this.$route.params.customObjectId
            )
          "
          :data="endDateData"
          @onChange="onChangeEndDate"
          :isParameters="true"
        />
      </div>
    </div>
    <div class="row row-cols-1 mt-3">
      <div class="col col-md-4">
        <label for="TitleObject" class="form-label required">{{
          $t(
            "CalendarViews.TitleObject",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label>
        <FormSelect
          type="SelectWithRemoteDataAndInternalSearch"
          @onMultipleSelectChange="onChangeTitleObject"
          :isMultiple="true"
          :allowEmpty="false"
          :isParameters="true"
          :isGatewayRequest="true"
          :requestUrl="
            String.format(
              '/Lcdp-SummaryFields?parentId={0}&coId={0}',
              this.$route.params.customObjectId
            )
          "
        />
      </div>
      <div class="col col-md-4">
        <label for="Views" class="form-label required">{{
          $t(
            "CalendarViews.Views",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label>
        <FormSelect
          type="SelectWithLocalData"
          :data="viewData"
          :allowEmpty="false"
          @onMultipleSelectChange="onChangeViewData"
          :isMultiple="true"
        />
      </div>
      <div class="col col-md-4">
        <label for="DefaultView" class="form-label required">{{
          $t(
            "CalendarViews.DefaultView",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label>
        <FormSelect
          type="SelectWithLocalData"
          :data="defaultViewData"
          :allowEmpty="false"
          @onChange="onChangeForDefaultViewData"
        />
      </div>
    </div>
    <div class="row row-cols-1 mt-3">
      <div class="col col-md-4">
        <label for="TimeLineField" class="form-label">{{
          $t(
            "CalendarViews.TimeLineField",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label>
        <FormSelect
          type="SelectWithRemoteDataAndInternalSearch"
          @onChange="onChangeTimeLine"
          :isParameters="true"
          :isGatewayRequest="true"
          :requestUrl="
            String.format(
              '/Lcdp-SummaryFields?parentId={0}&coId={0}',
              this.$route.params.customObjectId
            )
          "
        />
      </div>
      <div class="col col-md-4">
        <label for="MinTime" class="form-label required">{{
          $t(
            "CalendarViews.MinTime",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label>
        <DatePicker
          type="time"
          id="MinTime"
          :displayFormat="$store.getters._timeFormat"
          :isRequired="true"
          :allowClear="false"
          @valueChanged="onChangeTimeMinPicker"
        />
      </div>
      <div class="col col-md-4">
        <label for="MaxTime" class="form-label required">{{
          $t(
            "CalendarViews.MaxTime",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label>
        <DatePicker
          type="time"
          id="MaxTime"
          :displayFormat="$store.getters._timeFormat"
          :isRequired="true"
          :allowClear="false"
          @valueChanged="onChangeTimeMaxPicker"
        />
      </div>
    </div>
    <div class="row row-cols-1 mt-3">
      <div class="col col-md-4">
        <label for="ColorField" class="form-label"
          >{{
            $t(
              "CalendarViews.ColorField",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}
          <i
            class="bi bi-info-circle-fill"
            data-bs-toggle="tooltip"
            data-bs-html="true"
            :title="
              this.$t(
                'CalendarViews.ColorFieldFormulaExample',
                {},
                { locale: this.$store.state.activeLang }
              )
            "
          ></i
        ></label>
        <FormSelect
          type="SelectWithRemoteDataAndInternalSearch"
          @onChange="onChangeColorField"
          :isDetailIconShow="true"
          :routeLink="
            String.format(
              '#/CustomObject/EditField/{0}&fieldId=',
              this.$route.params.customObjectId
            )
          "
          :isParameters="true"
          :isGatewayRequest="true"
          :requestUrl="
            String.format(
              '/Lcdp-SummaryFieldsByType?parentId={0}&type=FormulaString',
              this.$route.params.customObjectId
            )
          "
        />
      </div>
      <div class="col col-md-4">
        <label for="DataFetchSize" class="form-label required"
          >{{
            $t(
              "CalendarViews.DataFetchSize",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}
        </label>
        <input
          type="number"
          class="form-control"
          id="DataFetchSize"
          v-model="calendarViewData.dataFetchSize"
          :autocomplete="this.$isAutoComplete"
          :spellcheck="this.$isTextSpellCheck"
          min="1"
          required
        />
      </div>
      <div class="col col-md-4">
        <label
          for="PageDesignerPublicId"
          class="form-label"
          :class="{
            required:
              calendarViewData.isInsertOnCalendar ||
              calendarViewData.isEditableOnCalendar,
          }"
          >{{
            $t(
              "CalendarViews.PageLayout",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}</label
        >
        <FormSelect
          type="SelectWithRemoteDataAndInternalSearch"
          @onChange="onChangePageLayout"
          :isDetailIconShow="true"
          :allowEmpty="
            !calendarViewData.isInsertOnCalendar &&
            !calendarViewData.isEditableOnCalendar
          "
          :routeLink="
            String.format(
              '#/CustomObject/EditPageLayout/{0}&pageLayoutId=',
              this.$route.params.customObjectId
            )
          "
          :isParameters="true"
          :isGatewayRequest="true"
          :requestUrl="
            String.format(
              '/Lcdp-SummaryPageLayouts?parentId={0}&coId={0}',
              this.$route.params.customObjectId
            )
          "
        />
      </div>
    </div>
    <div class="row rows-cols-1 mt-3">
      <div class="col col-md-4">
        <label
          for="LightboxRate"
          class="form-label"
          :class="{
            required:
              calendarViewData.isInsertOnCalendar ||
              calendarViewData.isEditableOnCalendar,
          }"
          >{{
            $t(
              "CalendarViews.LightboxRate",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}
        </label>
        <h6 class="badge badge-warning text-dark ms-1">
          {{ calendarViewData.lightBoxRatio }}
        </h6>
        <input
          id="LightboxRate"
          type="range"
          class="form-range"
          min="1"
          max="100"
          v-model="calendarViewData.lightBoxRatio"
          @oninput="this.calendarViewData.lightBoxRatio = this.value"
        />
      </div>
    </div>
    <div class="row row-cols-1 mt-3">
      <div class="col">
        <div class="form-check">
          <label for="IsInsertOnCalendar" class="form-label">{{
            $t(
              "CalendarViews.IsInsertOnCalendar",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}</label>
          <input
            class="form-check-input"
            type="checkbox"
            id="IsInsertOnCalendar"
            v-model="calendarViewData.isInsertOnCalendar"
          />
        </div>
        <div class="form-check">
          <label for="IsEditableOnCalendar" class="form-label">{{
            $t(
              "CalendarViews.IsEditableOnCalendar",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}</label>
          <input
            class="form-check-input"
            type="checkbox"
            id="IsEditableOnCalendar"
            v-model="calendarViewData.isEditableOnCalendar"
          />
        </div>
        <div class="form-check">
          <label for="IsActive" class="form-label">{{
            $t(
              "BaseModelFields.IsActive",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}</label>
          <input
            class="form-check-input"
            type="checkbox"
            id="IsActive"
            v-model="calendarViewData.isActive"
          />
        </div>
      </div>
    </div>
    <ActionButtons
      v-if="$isMobile()"
      @methodSave="onSubmit('0', 'btn-save')"
      @methodSaveAndNew="onSubmit('1', 'btn-save-and-new')"
      @clear="onClear()"
      :isNewRecord="true"
    />
  </form>
</template>
<script>
import $ from "jquery";
export default {
  name: "CustomObjectNewCalendar",
  components: {},
  data() {
    return {
      submitButtonHtml: String.format(
        '<span class="loading-block"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span><span> {0}</span></span>',
        this.$t(
          "Buttons.PleaseWait",
          {},
          { locale: this.$store.state.activeLang }
        )
      ),
      disabledButtonClasses: ".btn-save, .btn-save-and-new, .btn-clear",
      errors: [],
      calendarViewData: {
        isActive: true,
        customObjectPublicId: this.$route.params.customObjectId,
        lightBoxRatio: 80,
      },
      endDateData: [],
      viewData: [
        { key: "1", value: "dayGridWeek" },
        { key: "2", value: "dayGridMonth" },
        { key: "3", value: "timeGridDay" },
        { key: "4", value: "timeGridWeek" },
        { key: "5", value: "listDay" },
        { key: "6", value: "listMonth" },
        { key: "7", value: "listYear" },
        { key: "8", value: "timeLineDay" },
        { key: "9", value: "timeLineWeek" },
        { key: "10", value: "timeLineMonth" },
        { key: "11", value: "timeLineYear" },
        { key: "12", value: "resourceTimelineDay" },
        { key: "13", value: "resourceTimelineWeek" },
        { key: "14", value: "resourceTimelineMonth" },
        { key: "15", value: "resourceTimelineYear" },
        { key: "16", value: "resourceDayGridDay" },
        { key: "17", value: "resourceDayGridWeek" },
        { key: "18", value: "resourceDayGridMonth" },
        { key: "19", value: "resourceTimeGridDay" },
        { key: "20", value: "resourceTimeGridMonth" },
      ],
      defaultViewData: [],
      isSuccess: false,
      orjEndDateData: [],
    };
  },
  methods: {
    onChangeColorField(selected) {
      this.calendarViewData.colorCodeFieldPublicId = selected.key;
    },
    onChangeTimeMinPicker: function (date) {
      this.calendarViewData.minTime = date;
      if (!String.isNullOrWhiteSpace(date)) {
        this.calendarViewData.minTime = this.$moment(date).format(
          this.$store.getters._timeFormat
        );
      }
    },
    onChangeTimeMaxPicker: function (date) {
      this.calendarViewData.maxTime = date;
      if (!String.isNullOrWhiteSpace(date)) {
        this.calendarViewData.maxTime = this.$moment(date).format(
          this.$store.getters._timeFormat
        );
      }
    },
    onChangeStartDate: function (selected) {
      this.calendarViewData.startDateFieldPublicId = selected.key;
      // const constEndDate = this.endDateData.filter(x => x.key != selected.key);
      // this.endDateData = constEndDate;
      this.endDateData = this.orjEndDateData;
      if (selected.key != null) {
        this.endDateData = this.endDateData.filter(
          (x) => x.key != selected.key
        );
      }
    },
    onChangeEndDate: function (selected) {
      this.calendarViewData.endDateFieldPublicId = selected.key;
    },
    onChangeTitleObject: function (ids) {
      this.calendarViewData.titleFieldPublicIds = ids;
    },
    onChangeForDefaultViewData: function (selected) {
      this.calendarViewData.defaultView = selected.value;
    },
    onChangeTimeLine: function (selected) {
      this.calendarViewData.timeLineFieldPublicId = selected.key;
    },
    onChangePageLayout: function (selected) {
      this.calendarViewData.pageDesignerPublicId = selected.key;
    },
    onChangeViewData(ids) {
      var self = this;
      this.defaultViewData = [];
      if (
        !String.isNullOrWhiteSpace(ids) &&
        ids.includes(this.$systemSeparator)
      ) {
        var arrayOfViews = [];
        arrayOfViews = ids.split(this.$systemSeparator);
        var viewName = "";
        arrayOfViews.forEach((v) => {
          var defaultTempView = this.viewData.find((f) => f.key == v);
          this.defaultViewData.push(defaultTempView);
          viewName += defaultTempView.value + self.$systemSeparator;
        });
        viewName = viewName.slice(0, -1);
        this.calendarViewData.views = viewName;
      } else {
        var defaultTemp = this.viewData.find((f) => f.key == ids);
        if (!defaultTemp) {
          this.calendarViewData.views = null;
          return;
        }
        this.defaultViewData.push(defaultTemp);
        this.calendarViewData.views = defaultTemp.value;
      }
    },
    getEndDateData() {
      this.$prodGatewayAxios
        .get(
          String.format(
            "/Lcdp-SummaryCalendarViewableFields?parentId={0}&coId={0}",
            this.$route.params.customObjectId
          )
        )
        .then((response) => {
          this.endDateData = response.data.items;
          this.orjEndDateData = response.data.items;
        });
    },
    onClear() {
      this.calendarViewData = {
        isActive: true,
        customObjectPublicId: this.$route.params.customObjectId,
        lightBoxRatio: 80,
      };
      $(".form-calendar-new").removeClass("was-validated");
    },
    onSubmit(submitType, buttonName) {
      var form = $(".form-calendar-new");
      form.addClass("was-validated");
      if (
        !form[0].checkValidity() ||
        form.find(".ms-invalid:visible").length > 0
      ) {
        return;
      }

      var button = $(String.format(".{0}", buttonName)),
        firstSpan = button.find("span:first"),
        loadingBlock = button.find(".loading-block"),
        disabledButtons = $(this.disabledButtonClasses);

      disabledButtons.prop("disabled", true);
      firstSpan.hide();
      if (loadingBlock.length === 0) {
        firstSpan.after(this.submitButtonHtml);
        loadingBlock = button.find(".loading-block");
      } else {
        loadingBlock.show();
      }

      this.errors = [];
      this.$prodGatewayAxios
        .post("/Lcdp-CalendarViewNew", { ...this.calendarViewData })
        .then((r) => {
          var response = r.data;
          if (response.isOk) {
            if (submitType == 0) {
              this.$router.push(
                "/CustomObject/CalendarViews/" +
                  this.$route.params.customObjectId
              );
            } else {
              this.isSuccess = true;
              setTimeout(
                () => (this.isSuccess = false),
                this.$appSaveAndNewMessageDuration
              );
              this.onClear();
              firstSpan.show();
              loadingBlock.hide();
              disabledButtons.prop("disabled", false);
              form.removeClass("was-validated");
            }
          } else {
            firstSpan.show();
            loadingBlock.hide();
            disabledButtons.prop("disabled", false);
            this.errors.push(response.msg);
          }
        })
        .catch((r) => {
          firstSpan.show();
          loadingBlock.hide();
          disabledButtons.prop("disabled", false);
          this.errors.push(r);
        });
    },
  },
  watch: {
    "$route.params.customObjectId"() {
      this.calendarViewData.customObjectPublicId =
        this.$route.params.customObjectId;
    },
  },
  mounted() {
    this.getEndDateData();
  },
};
</script>
